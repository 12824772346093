<template>
    <div>
        <TopNavigator />
        <div class="wrap">
            <div>
                <div class="title">How to start with PowerFun?</div>
                <div class="content">
                <div class="sub-content">
                    <p>You need a computer that supports the installation of PowerFun software and has been successfully installed. You also need a bicycle and an intelligent riding platform that has been connected!Double click the application. It should start immediately.</p>
                    <p>You will see the PowerFun login home page. Log in with your PowerFun account (The user name and password registered at the time of registration.)</p>
                    <img src="@/assets/p1.png" class="img">
                    <p>Log in to the home page, and then you can start a new journey! Congratulations, your home screen is shown below.</p>
                    <img src="@/assets/p2.png" class="img">
                    <p>Your current basic information (User Profile - name, time, date, gender, nationality, weight, height, FTP, receiving address).</p>
                    <img src="@/assets/p3.png" class="img">
                    <p>Click Device in the upper left corner where you can connect to your ant + connected device or Bluetooth connection device locally.</p>
                    <img src="@/assets/p4.png" class="img">
                    <img src="@/assets/p5.png" class="img">
                    <p>Right middle lower - click More here you can see Your profile Your personal riding record.<br/>
                    Right middle - click Routes to see how many times you ride on the route map, and then choose a route according to your favorite map.</p>
                    <img src="@/assets/p6.png" class="img">
                    <p>After choosing a route ,you will see the basic infomation of the route,which including the route leaderboard</p>
                    <img src="@/assets/p7.png" class="img">
                    <p>You can choose to ride alone or with multiple riders.</p>
                    <img src="@/assets/p9.png" class="img">
                    <p> You can see the baseic route information, and your real-time riding information.<br/>
                    You can see a list of people nearby on the left.<br/>
                    You can also switch the 3D view on the Upper right corner.</p>
                    <img src="@/assets/p8.png" class="img">
                 </div>
                          
                </div>
            </div>
        </div>
        <BottomNavigator />
    </div>
</template>

<script>
import TopNavigator from "@/components/TopNavigator";
import BottomNavigator from "@/components/BottomNavigator";
export default {
    name:'HowToStart',
    components: {
        TopNavigator,
        BottomNavigator,
    },
    mounted(){

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
.wrap {
    margin-bottom: 20rem;
    >div {
        width:1200rem;
        margin:0 auto;
        background: #252525;
        padding-bottom:21rem ;
        .title {
            color:#fff;
            height: 58rem;
            line-height: 58rem;
            font-size: 42rem;
            text-align: center;
            font-weight: 700;
            padding-top:21rem;
            font-family: "GenSenMaruGothicTW-Regular";
        }
        .content {
            margin-top: 31rem;
            border-top: 1rem solid #444444;
           
            font-family: "GenSenMaruGothicTW-Regular";
            p {
                font-size: 18rem;
                color:#a6a6a6;
                line-height: 1.8;
                padding-left: 25rem;
                padding-right: 25rem;
            }
            ul{
                font-size: 15rem;
                color:#a6a6a6;
                font-weight: 400;
                padding-right: 25rem;
            }
            .title{
                font-size: 32rem;
                color: #fff;
                font-weight: 700;
            }
            .sub-title{
                font-size: 20rem;
                color: #fff;
            }
            .img{
                width:1000rem;
                height:562.5rem;
                margin:32rem 100rem 42rem 100rem
            }
            .sub-content{
                margin-bottom:38rem;
                line-height:21rem;
            }
            .third-sub-title{
                font-size: 15rem;
                color: #fff;
                font-weight: 400;
            }
        }
    }
}
</style>